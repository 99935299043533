/** @jsx jsx */
import {useEffect, useState} from 'react'
import {jsx, css} from '@emotion/react'
import Layout from '../components/layout'
import {isUnsupported, isChrome} from '../util/userAgent'

import {logDplWithExtensionParams, EVENTS} from '../util/dpl'
import {setExtensionParamsToLocalStorage} from '../util/extensionMetadata'

import {chromeExtensionLink, firefoxExtensionStoreLink} from '../util/extensionStoreLinks'

import IndexUnsupported from '../components/unsupported/index-unsupported'

// this page redirects user to startpage extension store link for the appropriate browser
// it shows unsupported page for unsupported browsers
const RedirectPage = () => {
    const [showUnsupportedPage, setShowUnsupportedPage] = useState(false)

    useEffect(() => {
        if (isUnsupported()) {
            setShowUnsupportedPage(true)
        } else {
            setExtensionParamsToLocalStorage()
                .then(() => {
                    return logDplWithExtensionParams(EVENTS.StartpagePageLoad)
                })
                .then(() => {
                    if (isChrome()) {
                        window.location.href = chromeExtensionLink
                    } else {
                        window.location.href = firefoxExtensionStoreLink
                    }
                })
        }
    }, [])

    const RenderComponent = () => {
        if (showUnsupportedPage) {
            return <IndexUnsupported experiment="unsupported" />
        }
        return (
            <p
                css={css`
                    margin: 0 0.5rem;
                    font-size: 1.5rem;
                `}
            >
                Loading...
            </p>
        )
    }

    return <Layout>{RenderComponent()}</Layout>
}

export default RedirectPage
